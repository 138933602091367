import { render, staticRenderFns } from "./agentwallet.vue?vue&type=template&id=078b30d8&scoped=true"
import script from "./agentwallet.vue?vue&type=script&lang=js"
export * from "./agentwallet.vue?vue&type=script&lang=js"
import style0 from "./agentwallet.vue?vue&type=style&index=0&id=078b30d8&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "078b30d8",
  null
  
)

component.options.__file = "agentwallet.vue"
export default component.exports