<template>
  <div class="box">
    <div class="search">

      <el-form label-width="120px" label-height="60px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="账户信息：" prop="businessStatus">
              <el-input v-model="queryInfo.name" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商家信息：" prop="businessStatus">
              <el-input v-model="queryInfo.name" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="display: flex; justify-content: flex-end">
            <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
            <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          </el-col>
        </el-row>

      </el-form>
    </div>

    <div class="tab">
      <div class="add">

        <el-button type="primary" @click="added">导出</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%" show-summary height="calc(100vh - 380px)" border
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }">

        <el-table-column prop="businessName" label="商家名称" align="center">
        </el-table-column>
        <el-table-column prop="allowPayoutsNum" label="商家账号" width="100" align="center">
        </el-table-column>
        <el-table-column prop="name" label="可提现金额" align="center">

        </el-table-column>
        <el-table-column prop="name" label="账期金额" align="center">

        </el-table-column>
        <el-table-column prop="name" label="提现中" align="center">

        </el-table-column>
        <el-table-column prop="name" label="已提现金额" align="center">

        </el-table-column>
        <el-table-column prop=" " label="操作" width="200" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="deta(scope.row.id)">调整余额</el-button>
            <el-button type="text" @click="deta(scope.row.id)">详情</el-button>


          </template>


        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <!-- 详情弹窗 -->
    <el-drawer title="商家详情" :visible.sync="drawer" :direction="direction" size="60%" :before-close="handleClose">
      <div class="drawer">
        <div class="drauser">
          <div><span>商家ID:</span> 899798998</div>
          <div><span>商家名称:</span> {{ businessdetail.businessName }}</div>
          <div><span>法定代表人:</span> {{ businessdetail.userName }}</div>
          <div><span>法人联系电话:</span> {{ businessdetail.legalerPhone }}</div>
          <div><span>经营地址:</span> {{ businessdetail.provinceName }}</div>
          <div><span>经营范围:</span> {{ businessdetail.scope }}</div>
          <div><span>行业分类:</span> {{ businessdetail.industryType }}</div>
          <div><span>注册地址:</span> {{ businessdetail.registeredAddress }}</div>
          <div><span>营业执照有效期:</span>{{ businessdetail.licenseValidityDate }}</div>
          <div><span>连锁编码:</span> {{ businessdetail.chainCode }}</div>
        </div>
        <div class="draimg">
          <div class="imgbox">
            <div class="imgname">身份证:</div>
            <div class="img">
              <img :src="businessdetail.idCardImgFront" alt="">
              <img :src="businessdetail.idCardImgReverse" alt="">
            </div>
          </div>
          <div class="imgbox">
            <div class="imgname">营业执照:</div>
            <div class="img">
              <img :src="businessdetail.logoUrl" alt="">
            </div>
          </div>
          <div class="imgbox">
            <div class="imgname">资历证书:</div>
            <div class="img">
              <img :src="businessdetail.qualifications" alt="">

            </div>
          </div>
          <div class="imgbox">
            <div class="imgname">经营场景:</div>
            <div class="img">
              <img :src="businessdetail.qualifications" alt="">
            </div>
          </div>

        </div>
        <div class="bl">
          <div class="bl-li">
            <div class="bl-name">积分使用比例：</div>
            <div class="bls">
              <div>消费</div>
              <input v-model="detaillist.integralRatioUse" type="text">
              <div class="text-box">元</div>
            </div>
            <div class="bls">
              <div>获得</div>
              <input v-model="detaillist.integralRatioGet" type="text">
              <div class="text-box">积分</div>
            </div>
          </div>
          <div class="bl-li">
            <div class="bl-name">抽佣比例：</div>
            <div class="bls">
              <div>外卖</div>
              <input v-model="detaillist.takeawayRake" type="text">
              <div class="text-box">%</div>
            </div>
            <div class="bls">
              <div>团购</div>
              <input v-model="detaillist.buyRake" type="text">
              <div class="text-box">%</div>
            </div>
            <div class="bls">
              <div>到店扫码</div>
              <input v-model="detaillist.yardRake" type="text">
              <div class="text-box">%</div>
            </div>
          </div>
          <div class="bl-li">
            <div class="bl-name">账期设置：</div>
            <div class="bls">
              <div>账期</div>
              <input v-model="detaillist.accountTerm" type="text">
              <div class="text-box">天</div>
            </div>

          </div>
        </div>
        <div
          style="display: flex;margin-bottom: 10px; align-items: center;justify-content: space-between;padding-right: 100px;">
          <div class="qx">
            <el-switch v-model="detaillist.integralDeduceAuthority" @change='change' inactive-text="积分抵扣权限">
            </el-switch>
            <el-switch v-model="detaillist.platformDeliveryAuthority" inactive-text="平台配送权限">
            </el-switch>
            <el-switch v-model="detaillist.couponVerificateAuthority" inactive-text="优惠券核销权限">
            </el-switch>
            <el-switch v-model="detaillist.selfDeliveryAuthority" inactive-text="商家自配送权限">
            </el-switch>
          </div>
          <div class="but" style="margin-top: 20px;">
            <el-button type="danger" @click="drawer = false">取消 </el-button>
            <el-button type="primary" @click="detailok()">保存</el-button>
          </div>
        </div>
        <div class="drwdetail">
          <div style="padding-left: 20px;">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="余额存入记录" name="first">
                <div>
                  <el-table :data="yedetail" style="width: 100%" height="calc(100vh - 380px)" border :header-cell-style="{
                    'background-color': '#f2f2f2',
                    'text-align': 'center',
                    border: '1px solid #ddd',
                  }">
                    <el-table-column prop="name" label="交易说明" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="存入时间" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="存入金额" align="center">
                    </el-table-column>

                  </el-table>
                </div>
              </el-tab-pane>
              <el-tab-pane label="提现明细" name="second">
                <div>
                  <el-table :data="txdetail" style="width: 100%" height="calc(100vh - 580px)" border :header-cell-style="{
                    'background-color': '#f2f2f2',
                    'text-align': 'center',
                    border: '1px solid #ddd',
                  }">
                    <el-table-column prop="amount" label="提现金额" align="center">
                    </el-table-column>
                    <el-table-column prop="creatTime" label="提现日期" align="center">
                    </el-table-column>
                    <el-table-column prop="payoutsCode" label="提现到账银行卡" align="center">
                    </el-table-column>
                    <el-table-column prop="payoutsStatus" label="审核状态" align="center">
                    </el-table-column>
                    <el-table-column prop="creatTime" label="提现审核通过日期" align="center">
                    </el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>
              <el-tab-pane label="评论明细" name="third">
                <div>
                  <el-table :data="pjdetail" style="width: 100%" height="calc(100vh - 580px)" border :header-cell-style="{
                    'background-color': '#f2f2f2',
                    'text-align': 'center',
                    border: '1px solid #ddd',
                  }">
                    <el-table-column prop="userCode" label="评论用户ID" align="center">
                    </el-table-column>
                    <el-table-column prop="orderId" label="关联订单" align="center">
                    </el-table-column>
                    <el-table-column prop="content" label="评论内容" align="center">
                    </el-table-column>
                    <el-table-column prop="appraiseImg" label="评论图片视频" align="center">
                    </el-table-column>
                    <el-table-column prop="appraise" label="评分" align="center">
                    </el-table-column>
                    <el-table-column prop="creatTime" label="评论时间" align="center">
                    </el-table-column>

                  </el-table>
                </div>
              </el-tab-pane>
              <el-tab-pane label="店铺明细" name="fourth">
                <div>
                  <el-table :data="dpdetail" style="width: 100%" height="calc(100vh - 580px)" border :header-cell-style="{
                    'background-color': '#f2f2f2',
                    'text-align': 'center',
                    border: '1px solid #ddd',
                  }">
                    <el-table-column prop="name" label="店铺头像" align="center">
                    </el-table-column>
                    <el-table-column prop="店铺名称" label="邀请人手机号" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="店铺地址" align="center">
                    </el-table-column>


                  </el-table>
                </div>
              </el-tab-pane>
              <el-tab-pane label="合同管理" name="five">
                <div>
                  <div
                    style="height: 61px;line-height: 61px; padding: 2px 2px 2px 2px;border-radius: 5px;background-color: rgba(223, 243, 255, 0.56);">
                    已签约 2024-10-10</div>
                  <div>
                    <img style="width: 75px;height: 75px;" src="../../assets/images/ht.png" alt="">
                    <div>商家入驻合同.docx</div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>

          </div>

        </div>
      </div>
    </el-drawer>
    <el-dialog title="商家余额调整" :visible.sync="showDialog" width="600px" height="1000px">
      <div class="dia">
        <div class="diali">
          <div class="liname">余额调整</div>
          <input v-model="from.orderSq" type="text" placeholder="请输入">
        </div>





        <div class="but">
          <el-button @click="showDialog = false">取消 </el-button>
          <el-button type="primary" @click="bc()">保存 </el-button>
        </div>

      </div>
    </el-dialog>

  </div>

</template>

<script>
export default {
  data() {
    return {
      showDialog: false,
      drawer: false,

      tableData: [],
      total: 0,
      queryInfo: {
        pageSize: 10,
        pageCount: 1,
        currPage: 1,
        condition: {

        }
      },
      valuechange: '',
      form: {},
      tapshow: 0,
      options: [{
        value: '1',
        label: '店长'
      }, {
        value: '2',
        label: '员工'
      }],
      radio: '1',
      from: {},
      businessdetail: {},
      detaillist: {}

    };
  },
  created() {
    this.getList()
  },
  mounted() {

  },
  methods: {
    change() {
      console.log(this.valuechange);
      this.from.startDate = this.newDate(this.valuechange[0])
      this.from.endDate = this.newDate(this.valuechange[1])
    },
    newDate(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      var d = date.getDate()
      d = d < 10 ? '0' + d : d
      var h = date.getHours()
      h = h < 10 ? '0' + h : h
      var minute = date.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      var s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
    },
    //获取数据
    getList() {
      var that = this;
      that.$http.post("/business/list", that.queryInfo).then(function (response) {
        console.log(response.data.data, 'response.data.data');
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList()
    },
    search() {
      this.queryInfo.condition = this.form
      this.getList()
    },
    reset() {
      this.form = {}
      this.queryInfo.condition = {}
      this.getList()
    },
    deta(id) {
      this.drawer = true

      this.id = id
      var that = this;

      that.$http.post("/business/queryBusinessDetail", {
        id: id
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.businessdetail = response.data.data
          that.detaillist = response.data.data


        }
      });
    },
    added() {
      this.showDialog = true
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  .search {
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px 20px 10px 20px;

    .search-top {
      width: 1540px;
      height: 66px;
      padding: 2px 2px 2px 2px;
      background-color: rgba(30, 152, 215, 0.14);

      display: flex;
      align-items: center;
      margin-bottom: 50px;
      cursor: pointer;

      div {
        width: 150px;
        text-align: center;
      }

      .active {
        border-bottom: 2px solid red;
      }
    }
  }

  .finance {
    height: 132px;
    padding: 2px 2px 2px 2px;
    border-radius: 5px;
    background-color: #ffffff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-size: 16px;
    justify-content: space-around;

    .fin-box {
      display: flex;

      .fin-li {
        margin-left: 15px;
      }
    }
  }

  .tab {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px
  }

  .dia {
    .but {
      display: flex;
      justify-content: space-between;
      padding: 30px 200px;
    }

    .diali {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .liname {
        width: 100px;
      }

      input {
        height: 30px;
      }
    }

  }

  .drawer {
    height: 900px;
    overflow-y: scroll;

    .drauser {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;

      div {
        width: 30%;
        color: #847575;
        margin-bottom: 30px;

        span {
          color: #000;
        }
      }
    }

    .draimg {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;

      .imgbox {
        width: 30%;
        display: flex;

        .imgname {
          color: #000;
        }

        .img {
          margin-top: 40px;
          display: flex;

          img {
            width: 89px;
            height: 65px;
          }
        }

      }
    }

    .bl {
      padding: 0 30px;

      .bl-li {
        display: flex;
        align-items: center;
        margin-top: 15px;

        .bls {
          display: flex;
          align-items: center;
          margin-left: 15px;

          input {
            width: 100px;
            height: 32px;
            text-align: center
          }

          .text-box {
            width: 38px;
            height: 38px;
            background-color: rgba(230, 230, 230, 0.55);
            text-align: center;
            line-height: 38px;
          }
        }
      }
    }

    .qx {
      padding: 0 30px;
      margin-top: 30px;
    }
  }
}
</style>